import { memo } from "react";
import styled from "styled-components";

const Avatar = memo(({ address, size }) => {
  return (
    <AvatarStyled
      alt=""
      size={size}
      src={`https://avatars.dicebear.com/api/human/${address}.svg`}
    />
  );
});
export default Avatar;

const AvatarStyled = styled.img`
  width: ${(props) => props.size || "34px"};
  height: ${(props) => props.size || "34px"};
  border-radius: 50%;
  border: 1px solid #edeef3;
`;
