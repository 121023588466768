import styled from "styled-components";
import publicJS from "../../utils/publicJS";
import ChainListJson from "../../api/chainList.json";
import {useState} from "react";
import PublicJs from "../../utils/publicJS";
import {ethers} from "ethers";

const Box = styled.div`
  height: calc(100vh - 260px);
  width: calc(100vw - 255px);
  overflow: auto;
  th{
    img{
      cursor: pointer;
    }
  }
`

export default function VentureList(props){
    const { list,handleOrderArr } = props;
    const [ order,setOrder ] = useState(new Array(7).fill(0));

    const switchStatus = (status) =>{
        let str
        switch (status) {
            case 0:
                str ="In deposit"
                break;
            case 1:
                str ="In return"
                break;
            case 2:
                str ="In management"
                break;
            case 3:
                str ="In redemption"
                break;
            default:break;
        }
        return str;
    }

    const calcRatio = (item) =>{

        if(!item.raised_funds) {
            return '0.00';
        }else{
            return (item.tav / item.raised_funds).toFixed(2);
        }
    }
    const handleOrder = (type) =>{
        //Total Assets Value(U) 0
        // TAV growth in the past weeks(U) 1
        // TAV growth in the past month(U) 2
        // ET Holders 3
        // ET-holder growth in the past week 4
        // ET-holder growth in the past month 5
        // GP Members 6
        let arr;
        if(order[type]>0){
            arr = [...order];
        }else{
            arr= new Array(7).fill(0);
        }

        if(arr[type] === 2){
            arr[type] = 0;
        }else{
            arr[type]= arr[type] +1;
        }
        setOrder(arr);
        handleOrderArr(arr);
    }

    const toFormat = (num,dec) =>{
        const fnum = num ? ethers.utils.formatEther(num.toString(),dec): num;
        return Number(fnum.toString()).toFixed(2)
    }


    return <Box>
        <div>
              <table cellPadding="0" cellSpacing="0" className="table">
                    <thead>
                        <tr>
                            <th>Date Created</th>
                            <th>State</th>
                            <th>Main-net</th>
                            <th>Fund Type</th>
                            <th>Handle</th>
                            <th>Name</th>
                            <th>
                                <div className="cur" onClick={()=>handleOrder(0)}>
                                    Total Assets Value(U)
                                    <img src={PublicJs.returnImg(order[0])} alt=""/>
                                </div>
                            </th>
                            <th>
                                <div className="cur" onClick={()=>handleOrder(1)}>
                                    TAV growth in the past week(U)
                                    <img src={PublicJs.returnImg(order[1])} alt="" />
                                </div>

                            </th>
                            <th>
                                <div className="cur" onClick={()=>handleOrder(2)}>
                                    TAV growth in the past month(U)
                                    <img src={PublicJs.returnImg(order[2])} alt="" />
                                </div>

                            </th>
                            <th>Raised Funds(U)</th>
                            <th>TAV/RF</th>
                            <th>Owner Address</th>
                            <th>Vault Address</th>
                            <th>Funding Participants</th>
                            <th>
                                <div className="cur">
                                    ET Holders
                                    <img src={PublicJs.returnImg(order[3])} alt=""  onClick={()=>handleOrder(3)}/>
                                </div>

                            </th>
                            <th>
                                <div className="cur">
                                    ET-holder growth in the past week
                                    <img src={PublicJs.returnImg(order[4])} alt=""  onClick={()=>handleOrder(4)}/>
                                </div>
                            </th>
                            <th>
                                <div className="cur">
                                    ET-holder growth in the past month
                                    <img src={PublicJs.returnImg(order[5])} alt=""  onClick={()=>handleOrder(5)}/>
                                </div>

                            </th>
                            <th>
                                <div className="cur">
                                    GP Members
                                    <img src={PublicJs.returnImg(order[6])} alt=""  onClick={()=>handleOrder(6)}/>
                                </div>
                            </th>
                            <th>Votes</th>
                            <th>Number of successful executions vote </th>
                            <th>Swap transactions</th>
                            <th>AAVE transactions</th>
                            <th>NFT Funds</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        list.map((item,index)=>(<tr key={index}>

                            <td className="num">{publicJS.formatTime(item.create_at)}</td>
                            <td><span className={`tag tag_${item.period}`}>{switchStatus(item.period)}</span></td>
                           <td>
                               {
                                   ChainListJson[item.chain_id] &&<><img src={ChainListJson[item.chain_id].image} alt="" className="symbol"/>
                                       {ChainListJson[item.chain_id].name}</>
                               }
                            </td>

                            <td>
                                {
                                    item.fund_token_symbol && <img src={`https://raw.githubusercontent.com/SubDAO-Network/daos/main/logo/${item.fund_token_symbol}.svg`} alt="" className="symbol2"/>
                                }

                                {item.fund_token_symbol}</td>
                            <td>{item.handle}</td>
                            <td>{item.name}</td>
                            <td className="num">{Number(item.tav).toFixed(2)}</td>
                            <td className="num">{Number(item.tav_growth_last_week).toFixed(2) }</td>
                            <td className="num">{Number(item.tav_growth_last_month).toFixed(2)}</td>
                            {/*<td className="num">{toFormat(item.raised_funds,item.fund_token_decimal)}</td>*/}
                            <td className="num">{Number(item.raised_funds_usdt).toFixed(2)}</td>
                            <td className="num">{calcRatio(item)} </td>
                            <td className="num">{item.owner_addr}</td>
                            <td className="num">{item.vault_addr}</td>
                            <td className="num">{item.funding_participants}</td>
                            <td className="num">{item.et_holders}</td>
                            <td className="num">{item.et_holders_growth_last_week}</td>
                            <td className="num">{item.et_holders_growth_last_month} </td>
                            <td className="num">{item.gp_member_count}</td>
                            <td className="num">{item.vote_count}</td>
                            <td>{item.success_vote_count}</td>
                            <td>{item.buy_count}</td>
                            <td>{item.loan_count}</td>
                            <td>{item.nft_count}</td>
                        </tr>))
                    }

                    </tbody>
              </table>
        </div>
    </Box>
}