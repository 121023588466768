import { ethers } from "ethers";
import {Buffer} from 'buffer';

export const signMessage = (chainId,account, timestamp) => {
  const msg = `rfa#${chainId}#${account}#${timestamp}`;

  try {
    const sign_1 = `0x${Buffer.from(msg, "utf8").toString("hex")}`;
    const sign_2 = ethers.utils.keccak256(sign_1);
    const sign_3 = `0x${Buffer.from(sign_2, "utf8").toString("hex")}`;
    return sign_3;
  } catch (err) {
    console.error(err);
  }
};
