const reducer = (state, action) => {
    switch (action.type) {

        case 'CONNECT_INIT':
            return { ...state, web3ProviderState: 'CONNECT_INIT' };

        case 'CONNECT':
            return { ...state, web3Provider: action.payload, web3ProviderState: 'READY' };

        case 'CONNECT_ERROR':
            return { ...state,web3ProviderState: 'ERROR', web3ProviderError: action.payload };

        //account
        case 'SET_ALLACCOUNT':
            return { ...state, Account: action.payload, accountState: 'READY' };

        case 'SET_tips':
            return { ...state, error: action.payload };

        case 'SET_TOKEN':
            return { ...state, token: action.payload };

        case 'SET_ERROR':
            return { ...state, errorTips: action.payload };

        default:
            throw new Error(`Unknown type: ${action.type}`);
    }
};
export default reducer
