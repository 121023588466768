import styled from "styled-components";
import ChainListJson from "../../api/chainList.json";
import PublicJs from "../../utils/publicJS";
import {useState} from "react";
import publicJS from "../../utils/publicJS";

const Box = styled.div`
  height: calc(100vh - 260px);
  width: calc(100vw - 255px);
  overflow: auto;
  th{
    img{
      cursor: pointer;
    }
  }
`

export default function AddressList(props){
    const { list,handleOrderArr } = props;
    const [ order,setOrder ] = useState(new Array(2).fill(0));

    const handleOrder = (type) =>{
        //Total Deposit Amount(U) 0
        //Times 1
        let arr;
        if(order[type]>0){
            arr = [...order];
        }else{
            arr= new Array(2).fill(0);
        }

        if(arr[type] === 2){
            arr[type] = 0;
        }else{
            arr[type]= arr[type] +1;
        }
        setOrder(arr);
        handleOrderArr(arr);
    }
    return <Box>
        <div>
              <table cellPadding="0" cellSpacing="0" className="table">
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Address</th>
                            <th>Main-net</th>
                            <th>
                                <div className="cur" onClick={()=>handleOrder(0)}>
                                    Total Deposit Amount(U)
                                    <img src={PublicJs.returnImg(order[0])} alt="" />
                                </div>

                            </th>
                            <th>
                                <div className="cur" onClick={()=>handleOrder(1)}>
                                    Deposit Times
                                    <img src={PublicJs.returnImg(order[1])} alt="" />
                                </div>

                            </th>
                            <th>Date of the last time</th>
                            <th>IP</th>
                            <th>IP country</th>
                            <th>Total deposit times past week</th>
                            {/*<th>Total raised times growth past week</th>*/}
                            <th>Total redeem times</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        list.map((item,index)=>(<tr key={index}>
                            <td>{index+1}</td>
                            <td>{item.address}</td>
                            <td>
                                {
                                    ChainListJson[item.chain_id] &&<><img src={ChainListJson[item.chain_id].image} alt="" className="symbol"/>
                                        {ChainListJson[item.chain_id].name}</>
                                }
                            </td>
                            <td>{Number(item.total_raised_amount_usdt).toFixed(2)}</td>
                            <td>{item.total_raised_times}</td>
                            <td>{publicJS.formatTime(item.last_raise_date)}</td>
                            <td>{item.ip}</td>
                            <td>{item.ip_country}</td>
                            <td>{item.total_raised_times_past_week}</td>
                            {/*<td>{item.total_raised_times_growth_past_week}</td>*/}
                            <td>{item.total_redeem_times}</td>
                        </tr>))
                    }
                    </tbody>
              </table>
        </div>
    </Box>
}