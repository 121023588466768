import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { ApolloProvider } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import {useEffect, useMemo, useState} from "react";

const HttpProvider = ({ children }) => {
    const [ url, setUrl] = useState('')

    useEffect(()=>{
        if(window.location.host.indexOf('network')>-1){
            setUrl(window.BaseUrl.main)
        }else{
            setUrl(window.BaseUrl.dev)
        }
    },[])

    const client = useMemo(() => {
    const httpLink = createHttpLink({
      uri: `${url}/query`,
    });
    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          Authorization: localStorage.getItem("SubDAO_user_token") || "",
        },
      };
    });
    return new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
    });
  }, [url]);
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default HttpProvider;
