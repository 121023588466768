import styled from "styled-components";
import TavList from "./tav/tavList";
import TavChart from "./tav/tavChart";

const Box = styled.div`
    display: flex;
    flex-direction: column;
    justify-content:space-between;
    height: 100%;
`

const MainContent = styled.div`
  overflow: hidden;
`

export default function Tav(){
    return <Box>
        <div>
            <TavList />
        </div>
        <MainContent>
            <TavChart />
        </MainContent>
    </Box>
}