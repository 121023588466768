import styled from "styled-components";
import LogoImg from "../images/logo.svg";
import {useWeb3} from "../api/contracts";
import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import ChainListJSON from "../api/chainList.json";
import { checkTokenValid } from "../utils/auth";
import {useLazyQuery} from "@apollo/client";
import {LoginQuery} from "../api/graphqlAPI";
import {signMessage} from "../utils/sign";
import publicJS from "../utils/publicJS";
import Avatar from "./avatar";

const Box = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 17px 30px;
`
const RhtBox = styled.div`
    display: flex;
    .selectedBox{
      background: #fff;
    }
`
const AddressBox = styled.div`
  height: 38px;
  background: #F5F8FD ;
  border-radius: 8px;
  border: 1px solid #E9EAF0;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px ;
  font-size: 14px;
  font-family: "AvenirNext-Medium";
  font-weight: 500;
  color: #0E164E;
  line-height: 20px;
  position: relative;
  cursor: pointer;
  span{
    margin-left: 6px;
  }
`

const DisconnectBtn = styled('div')`
  width: 100%;
  height: 38px;
  background: #FFFFFF;
  box-shadow: inset 0px 3px 3px 0px rgba(175,177,198,0.1);
  border-radius: 8px;
  border: 1px solid #E9EAF0;
  line-height: 38px;
  text-align: center;
  position: absolute;
  left: 0;
  top:45px;
  z-index: 99;
`

export default function Header(){
    const {  dispatch, state } = useWeb3();
    const { web3Provider, Account} = state;
    const navigate = useNavigate();
    const location = useLocation();
    const [show,setShow] = useState(false);
    const [chain,setChain] = useState(null);
    const [showBtn,setShowBtn] = useState(false);
    const [signData,setSignData] = useState(null);
    const [nowTime,setNowTime] = useState();

    useEffect( () => {
        if(Account == null|| chain==null) return;
        if (!checkTokenValid()) {
            handleLogin();
        }
    }, [Account,chain]);

    useEffect(()=>{
        document.addEventListener("click",()=>{
            setShowBtn(false);
        });
        const { ethereum,location} = window;
        if(typeof ethereum == 'undefined'){
            dispatch({type: 'SET_ERROR',payload: {msg:"Please install MetaMask"}});
        }else{
            ethereum.on('chainChanged', (chainId) => {
                dispatch({type: 'SET_ALLACCOUNT',payload:null});
                sessionStorage.removeItem('account');
                localStorage.removeItem("SubDAO_user_token");
                localStorage.removeItem("SubDAO_user_token_expire_at");

                navigate("/");
                location.reload();
            });
            ethereum.on('accountsChanged', function (arr) {
                dispatch({type: 'SET_ALLACCOUNT',payload:null});
                sessionStorage.removeItem('account');
                navigate("/");
                location.reload();
            });
        }
    },[])

    useEffect(() => {
        // chainChange();
        if(web3Provider == null )return;
        let selectedStorage = sessionStorage.getItem('account');
        let token = localStorage.getItem("SubDAO_user_token");
        if (selectedStorage) {
            dispatch({type: 'SET_ALLACCOUNT',payload:selectedStorage});
            dispatch({type: 'SET_TOKEN',payload:token});
        }
        getChainID();
    }, [web3Provider]);


    const [doLogin, { loading }] = useLazyQuery(LoginQuery, {
        onCompleted: (data) => {
            const loginData = data.loginWithWhiteList;
            dispatch({type:"SET_TOKEN",payload:loginData.token})
            localStorage.setItem("SubDAO_user_token", loginData.token);
            localStorage.setItem(
                "SubDAO_user_token_expire_at",
                String(Date.now() + loginData.tokenExp * 1000)
            );
            navigate(`/main/0`)
        },
        onError: (error) => {
            dispatch({type:"SET_tips",payload:error.message});
        },
    });

    useEffect(()=>{
        if(signData ==null) return;
       const getLogin = async() =>{
           await doLogin({
               variables: {
                   sign: signData,
                   account:Account,
                   timestamp: nowTime,
                   chainID:chain?.chainId?chain?.chainId:1
               },
           });
        }
        getLogin()
    },[signData])

    const handleLogin = async () => {
        const now = Date.now();
        setNowTime(now);
        try{
            const signD = await web3Provider.send("personal_sign", [
                signMessage(chain?.chainId,Account, now),
                Account,
            ]);
            setSignData(signD)
        }catch (err){
            console.log(err)
            logout()
        }
    };

    useEffect(()=>{
        if(location?.pathname === '/home'){
            setShow(false);
        }else{
            setShow(true);
        }
    },[location]);

    const logout = () =>{
        dispatch({type: 'SET_ALLACCOUNT',payload:null});
        sessionStorage.removeItem('account');
        localStorage.removeItem("SubDAO_user_token");
        localStorage.removeItem("SubDAO_user_token_expire_at");
        navigate("/");
        window.location.reload();
        setShowBtn(false);
    }

    const handleDisconnect = (e) =>{
        setShowBtn(true);
        e.nativeEvent.stopImmediatePropagation();
    }

    const getChainID = async () =>{
        let chainInfo = await web3Provider.getNetwork();
        const { chainId } = chainInfo;
        setChain(ChainListJSON[chainId])
    }
    return  <Box>
        <div>
            <img src={LogoImg} alt=""/>
        </div>
        {
            show && Account!=null  &&  <RhtBox>
                {
                    chain !=null &&<div className="selectedBox">
                        <div className="content">
                            <div>
                                <img src={chain.image} alt="" className="symbolImg"/>
                                <span className="symbol">{chain.name}</span>
                            </div>

                            <img src="" alt=""/>
                        </div>
                    </div>
                }
                <AddressBox onClick={(e)=>handleDisconnect(e)}>
                    {
                        showBtn &&<DisconnectBtn onClick={()=>logout()}>Disconnect</DisconnectBtn>
                    }

                    {/*<img src="https://wx2.sinaimg.cn/mw2000/a66d0169ly8h4um03f30ej21zt1zthdt.jpg" alt=""/>*/}
                    <Avatar address={Account} size="24px"/>
                    <span className="symbol">{publicJS.AddressToShow(Account)}</span>
                </AddressBox>
            </RhtBox>
        }

    </Box>
}