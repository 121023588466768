import styled from "styled-components";
import VentureImg from "./images/venture.png";
import TAVImg from "./images/TAV.png";
import AddressImg from "./images/address.png";
import VentureHover from "./images/venture_hover.png";
import TAVHover from "./images/TAV_hover.png";
import AddressHover from "./images/address_hover.png";
import {useEffect, useState} from "react";

import Venture from "./components/venture";
import Tav from "./components/tav";
import Address from "./components/address";
import {useNavigate, useParams} from "react-router-dom";

const MainContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  height: calc(100% - 20px);
  margin: 0 10px 20px;
`
const Lft = styled.div`
  width: 185px;
  margin-right: 10px;
  flex-shrink: 0;
  height: 100%;
  background: #FFFFFF;
  border-radius: 14px;
  padding: 36px 15px;
`
const Rht = styled.div`
    flex-grow: 1;
  background: #FFFFFF;
  border-radius: 14px;  
  padding: 30px 20px;
`

const UlBox =styled.div`
    li{
      display: flex;
      align-items: center;
      padding: 13px 11px;
      font-size: 14px;
      font-family: "AvenirNext-Medium";
      font-weight: 600;
      line-height: 19px;
      width: 155px;
      height: 50px;
      border-radius: 14px;
      cursor: pointer;
      &.active {
        background: #D51172;
        color: #ffffff;
      }
      img{
        margin-right: 7px;
      }
    }
`
function Main() {
    const [ current,setCurrent ] = useState(0);
    const params = useParams();
    const navigate = useNavigate();
    const [list] = useState([
        {
            name: 'Venture DAOs',
            img:VentureImg,
            img_hover:VentureHover
        },
        {
            name: 'TAV',
            img:TAVImg,
            img_hover:TAVHover
        },
        {
            name: 'Address',
            img:AddressImg,
            img_hover:AddressHover
        }
    ]);
    const handleClick = (index) =>{
        // setCurrent(index);
        navigate(`/main/${index}`)
    }
    useEffect(()=>{

        setCurrent(parseInt(params.id));
    },[params.id])

    return (
        <MainContent>
            <Lft>
                <UlBox>
                    {
                        list.map((item,index)=>(<li className={current === index ?'active':''} key={index} onClick={()=>handleClick(index)}>
                            {
                                current === index &&<img src={item.img_hover} alt="" />
                            }
                            {
                                current !== index &&<img src={item.img} alt="" />
                            }

                            <span>{item.name}</span>
                        </li>))
                    }

                </UlBox>
            </Lft>
            <Rht>
                {
                    current === 0 && <Venture />
                }
                {
                    current === 1 && <Tav />
                }
                {
                    current === 2 && <Address />
                }
            </Rht>
        </MainContent>
    );
}

export default Main;
