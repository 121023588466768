import OrderTopImg from "../images/orderTop.png";
import OrderDownImg from "../images/orderDown.png";
import OrderImg from "../images/order.png";

export default {
     AddressToShow(address){
        let frontStr = address.substring(0, 5);
        let afterStr = address.substring(address.length - 5, address.length);
        return `${frontStr}...${afterStr}`
    },
    formatTime(time){
        const date= new Date(time);
        const year = date.getFullYear(),
            month = date.getMonth()+1,
            day = date.getDate(),
            hour = date.getHours(),
            min = date.getMinutes(),
            sec = date.getSeconds();
        const newTime = year + '-' +
            (month < 10? '0' + month : month) + '-' +
            (day < 10? '0' + day : day) + ' ' +
            (hour < 10? '0' + hour : hour) + ':' +
            (min < 10? '0' + min : min) + ':' +
            (sec < 10? '0' + sec : sec);
        return newTime;
    },
     returnImg (type){
        let img
        if(type === 1){
            img = OrderTopImg;
        }else if(type === 2){
            img = OrderDownImg;
        }else {
            img = OrderImg;
        }
        return img;
    }
}
