import styled from "styled-components";
import {Button} from "react-bootstrap";
import SelectedImg from "../images/selected.png";
import MagnifierImg from "../images/magnifier.svg";
import VentureList from "./venture/ventureList";
import Page from "./pagination";
import {useEffect, useState,useMemo} from "react";
import ChainList from "./chainList";
import {ventureDAOList,chainListQuery} from "../api/graphqlAPI";
import {useLazyQuery,useQuery} from "@apollo/client";
import ChainListJson from "../api/chainList.json"
import debounce from "lodash.debounce";
import {useWeb3} from "../api/contracts";

const Box = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`
const Top = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
`
const BtnBox = styled(Button)`
    border-radius: 8px;
  width: 92px;
  height: 38px;
  background: #D51172;
  box-shadow: inset 0px 3px 3px 0px rgba(175,177,198,0.1);
  font-size: 16px;
  font-family: "AvenirNext-Medium";
  font-weight: 500;
  color: #FFFFFF;
  line-height: 22px;
`
const MainContent = styled.div`
    flex-grow: 1;
  overflow: hidden;
`
const Btm = styled.div`
  margin-top: 20px;
`
const LftBox = styled.div`
    display: flex;
    align-items: center;
  .content{
  }
  .lftName{
    margin-left: 5px;
  }
`
const StatusBox = styled.ul`
    position: absolute;
  background: #FFFFFF;
  border-radius: 8px;
  border: 1px solid #EDEEF3;
  left: 0;
  top:40px;
  width: 100%;
  padding: 10px 0;
  li{
    padding-left: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #2E396E;
    line-height: 30px;
    &:hover{
      background: #FBFBFA;
      cursor: pointer;
    }
  }
`


export default function Venture(){
    const {state} =useWeb3();
    const [ url, setUrl] = useState('');
    const{ web3Provider,Account,token} =state;
    const [showStates,setShowStates] = useState(false);
    const [showChain,setShowChain] = useState(false);
    const [currentStates,setCurrentStates] = useState(0);
    const [currentChain,setCurrentChain] = useState(null);
    const [chainId, setChainid] = useState();
    const [list] = useState([
        {
            name:"All States",
            value:null
        },
        {
            name:"In deposit",
            value:0
        },
        {
            name:"In return",
            value:1
        },
        {
            name:"In management",
            value:2
        },
        {
            name:"In redemption",
            value:3
        }]);
    const [page,setPage] = useState(1);
    const [pageSize,setPageSize] = useState(10);
    const [total,setTotal] = useState(0);
    const [orderStr, setOrderStr ] = useState('desc');
    const [sort_field, setSort_field ] = useState('create_at');
    const [chainList,setChainList] = useState([]);
    const [dataList,setDataList] = useState([]);
    const [keyword,setKeyword] = useState('');
    const [chainListQ,setChainListQ] = useState('');

    // const { data:chainListQ} = useQuery(chainListQuery);

    const [initChain, {data:chainListResult}] = useLazyQuery(chainListQuery,{
        onCompleted: (result) => {
            setChainListQ(result)
        },
        onError: error => {
            console.log(error)
        }
    });

    useEffect(()=>{
        if(window.location.host.indexOf('org')>-1){
            setUrl(window.BaseUrl.dev)
        }else{
            setUrl(window.BaseUrl.main)
        }

        const GetChain = async() =>{
            let chainInfo = await web3Provider.getNetwork();
            const { chainId } = chainInfo;
            setChainid(chainId)
        }

        GetChain()
    },[])

    useEffect(()=>{
        if(Account ==null)return;
        initChain()
    },[Account])
   

    useEffect(()=>{
        if (chainListQ){
            setChainList(chainListQ.chains)
        }
    },[chainListQ])

    const [init, {data}] = useLazyQuery(ventureDAOList,{
        onCompleted: data => {
            setDataList(data.ventureDAOList.data);
            setTotal(data.ventureDAOList.total);
        },
        onError: error => {
            console.log(error)
        }
    });

    const initInfo = () =>{
        init({
            variables:{
                page,
                size:pageSize,
                order:orderStr,
                sort_field:sort_field,
                search_txt:keyword,
                period:list[currentStates].value,
                chainID:currentChain
            }
        })
    }
    useEffect(()=>{
        if(Account==null)return;
        initInfo()
    },[page,pageSize,orderStr,sort_field,keyword,currentStates,currentChain,Account,token]);

    useEffect(() => {
        document.addEventListener("click", (e) =>{
            setShowStates(false);
            setShowChain(false);
        });
    }, [])

    const handleStatus = (e) =>{
        e.nativeEvent.stopImmediatePropagation();
        setShowStates(true);
        setShowChain(false);
    }

    const handleSelectdStates = (num) =>{
        setCurrentStates(num)
    }
    const handleChain = (e) =>{
        e.nativeEvent.stopImmediatePropagation();
        setShowChain(true);
        setShowStates(false);
    }

    const handleSelectdChain = (num) =>{
        setCurrentChain(num)
    }

    const handlePage = (num) =>{
        setPage(num+1)
    }
    const handlePageSize= (num) =>{
        setPageSize(num);
    }
    const getIndex = (arr) =>{
        let ind
        for(let i = 0; i < arr.length; i++){
            if(arr[i] !== 0){
               ind= i;
            }
        }
        return ind;
    }
    const handleOrder = (order) =>{
        let i =  getIndex(order);

        let sort_field;
        let orderStr;
        //Total Assets Value(U) 0
        // TAV growth in the past weeks(U) 1
        // TAV growth in the past month(U) 2
        // ET Holders 3
        // ET-holder growth in the past week 4
        // ET-holder growth in the past month 5
        // GP Members 6
        switch (i){
            case 0:
                sort_field = "tav";
                break;
            case 1:
                sort_field = "tav_growth_last_week";
                break;
            case 2:
                sort_field = "tav_growth_last_month";
                break;
            case 3:
                sort_field = "et_holders";
                break;
            case 4:
                sort_field = "et_holders_growth_last_week";
                break;
            case 5:
                sort_field = "et_holders_growth_last_month";
                break;
            case 6:
                sort_field = "gp_member_count";
                break;
            default:
                sort_field = "create_at";
                break;
        }
        orderStr = order[i] === 1 ? 'asc' :'desc';
        setOrderStr(orderStr);
        setSort_field(sort_field);
    }

    const handleKeyword = (e) =>{
        setKeyword(e.target.value)
    }
    const debouncedChangeHandler = useMemo(() => {
        return debounce(handleKeyword, 300);
    }, []);

    return <Box>
        <Top>
            <LftBox>
                <div className="searchInput">
                    <img src={MagnifierImg} alt=""/>
                    <input type="text" placeholder="Handle/Address"  onChange={debouncedChangeHandler}/>
                </div>
                <div className="selectedBox">
                    {
                        showStates && <StatusBox>
                            {
                                list.map((item,index)=>(<li key={index} onClick={()=>handleSelectdStates(index)}>{item.name}</li>))
                            }
                        </StatusBox>
                    }
                    <div className="content" onClick={handleStatus}>
                        <span className="lftName">{list[currentStates].name}</span>
                        <img src={SelectedImg} alt=""/>
                    </div>

                </div>
                <div className="selectedBox">
                    {
                        showChain && <ChainList handleSelectdChain={handleSelectdChain} chainList={chainList} />
                    }
                    <div className="content" onClick={handleChain}>
                        {
                            !!currentChain &&<div>
                                {
                                    ChainListJson[currentChain]?.image && <img src={ChainListJson[currentChain].image} alt="" className="symbolImg"/>
                                }

                                <span className="lftName">{ChainListJson[currentChain]?.name}</span>
                            </div>
                        }
                        {
                            !currentChain && <span className="lftName"><span>All main-nets</span></span>
                        }
                        <img src={SelectedImg} alt=""/>
                    </div>
                </div>
            </LftBox>
            {/*<a href={`${url}/export_venture_dao?chain_id=${chainId}&token=${token}`} target="_blank" rel="noreferrer"><BtnBox>Export</BtnBox></a>*/}
            <a href={`${url}/export_venture_dao?token=${token}`} target="_blank" rel="noreferrer"><BtnBox>Export</BtnBox></a>
        </Top>
        <MainContent>
            <VentureList list={dataList} handleOrderArr={handleOrder}/>
        </MainContent>
        {
            !!total && <Btm>
                <Page itemsPerPage={pageSize} total={total} current={page-1} handleToPage={handlePage} handlePageSize={handlePageSize}/>
            </Btm>
        }
    </Box>
}