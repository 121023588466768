
const { ethereum } = window;

const account = async () => {
    if (typeof ethereum == 'undefined') {
        return {
            type: 'error'
        };
    }
    const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
    sessionStorage.setItem('account',accounts[0]);
    return {
        type: 'success',
        data: accounts[0]
    }
}
export default {
    account,
}
