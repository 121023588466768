import { createGlobalStyle } from "styled-components";
import "../scss/fonts.scss";

const GlobalStyle = createGlobalStyle`
    body,html{
      height: 100% ;
      color: #10164B;
      font-weight: 400;
      background: #F7F9FF;
      font-family: "AvenirNext-Regular";
    }
    *::-webkit-scrollbar { width: 0 !important;display: none!important; }
    a{
      &:focus{
        box-shadow: none!important;
      }
    }
    .btn-primary {
      background-color:#D51172;
      color: white;
      border: 0;
      &:focus,&:active &:active:focus{
        outline: none;
        box-shadow: none!important;
        background-color:#D51172;
        border: 0;
      }
      &:hover{
        background-color:#D51172;
        opacity: 0.9;
      }
    }
    ul,li,dt,dd,dl{
      padding: 0;
      margin: 0;
      list-style: none;
    }
  input{
    outline: none;
    &::placeholder{
      font-size: 14px;
      font-weight: 400;
      color: rgba(14,22,78,0.4);
      line-height: 19px;
    }
  }
    .searchInput{
      display: flex;
      align-items: center;
      width: 360px;
      height: 38px;
      background: #F5F8FD;
      border-radius: 8px;
      border: 1px solid #E9EAF0;
      margin-right: 15px;
      img{
        width: 22px;
        margin:0 15px;
        flex-shrink: 0;
        
      }
      input{
        height: 100%;
        flex-grow: 1;
        background: transparent;
        border: 0;
        font-size: 14px;
      }
    }
    .selectedBox{
      min-width: 144px;
      height: 38px;
      background: #F5F8FD ;
      border-radius: 8px;
      border: 1px solid #E9EAF0;
      margin-right: 15px;

      padding: 0 7px ;
      position: relative;
      .content{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;
        height: 100%;
        cursor: pointer;
        &>div{
          display: flex;
          align-items: center;
        }
      }
      span{
        font-size: 14px;
        font-weight: 400;
        color: #0E164E;
        line-height: 19px;
      }
      img{
        margin-left: 5px;
      }
      .symbol{
        font-size: 16px;
        font-weight: 400;
        color: #0E164E;
        line-height: 22px;
      }
      .symbolImg{
        width: 24px;
        height: 24px;
        margin-right: 6px;
      }
    }
    .table{
      border: 0;
      min-width: 100%;
      border: 0;
      th{
        height: 50px;
        background: #F6F8FA;
        border: 0;
        color: rgba(14,22,78,0.4);
        vertical-align: middle;
        white-space: nowrap;
        font-size: 14px;
        font-family: "AvenirNext-Medium";
        font-weight: 500;
        padding:0 50px;
        text-align: center;
        img{
          width: 24px;
          height: 24px;
        }
        .cur{
          cursor: pointer;
        }
        &:first-child{
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          text-align: left;
        }
        &:last-child{
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
      td{
        border: 0;
        height: 50px;
        font-size: 14px;
        white-space: nowrap;
        text-align: center;
        vertical-align: middle;
        padding: 0 50px;
        .symbol{
          width: 16px;
          margin-right: 4px;
          margin-bottom: 4px;
        } 
        .symbol2{
          width: 24px;
          margin-bottom: 5px;
          margin-right: 4px;
        }
        &.num{
          font-family: "AvenirNext-DemiBold";
          font-weight: 600;
          color: #0E164E;
        }
        &:first-child{
          text-align: left;
        }
      }
    }
    .tag{
      height: 27px;
 
      border-radius: 6px;
      line-height: 27px;
      font-size: 14px;
      font-family: "AvenirNext-DemiBold";
      font-weight: 600;
      color: #FFFFFF;
      padding: 4px 12px;
      &_0{
        background: #02A177;
      }
      &_2{
        background: #1A2684;
      }
      &_3{
        background: #E90073;
      }
      &_1{
        background: #E6E8F1;
      }
    }
`;

export default GlobalStyle;
