import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import styled from "styled-components";
import LftImg from "../images/left.png";
import RhtImg from "../images/right.png";
import SelectedImg from "../images/selected.png";
import {Button} from "react-bootstrap";

const Box = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  a{
    text-decoration: none;
  }
  .page-break{
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    margin-right: -4px;
  }
  .page-link,.page-left,.page-right{
    width: 32px;
    height: 32px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #E6E6E6;
    margin-left: 8px!important;
    text-align: center;
    line-height: 32px;
    padding: 0;
    font-size: 14px;
    font-family: "AvenirNext-Regular";
    font-weight: 400;
    color: #0E164E;
    cursor: pointer;
  }
  .page-link{
    &:hover{
      border: 1px solid #D51172;
      color: #D51172;
    }
  }
  .page-left{
    background: url(${LftImg}) no-repeat center;
    &:hover{
      border-radius: 4px;
      border: 1px solid #E6E6E6;
      background:#F7F7F7 url(${LftImg}) no-repeat center;
    }
  }
  .page-right{
    background: url(${RhtImg}) no-repeat center;
    &:hover{
      border-radius: 4px;
      border: 1px solid #E6E6E6;
      background: #F7F7F7 url(${RhtImg}) no-repeat center;
    }
  }
  
  .pageL,.pageR{
    font-family: "AvenirNext-Medium";
    color: #0E164E;
    display: inline-block;
    width: 100%;
    height: 100%;
  }
  .disabled {
    .pageL {
      color: #f2f2f2 !important;
    }
    &:hover{
      border: 1px solid #E6E6E6!important;
    }
  }
  .active{
    .page-link{
      border: 1px solid #D51172;
      color: #D51172;
    }
  }
`
const NumBox = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #999DB5;
  line-height: 19px;
  margin-right: 10px;
  span{
    color: #0e164e;
  }
`
const SelectedBox = styled.div`
  width: 98px;
  height: 32px;
  background: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #E6E6E6;
  margin-left: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #171F54;
  position: relative;
  
  .selected{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    cursor: pointer;
  }
  span{
    flex-grow: 1;
    text-align: center;
  }
`
const DropDownBox = styled.ul`
  width: 99px;
  height: 111px;
  background: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #E6E6E6;
  position: absolute;
  top:-117px;
  left: 0;
  padding: 11px 0;
  li{
    height: 30px;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
  }
`

const GoToBox = styled.div`
    display: flex;
    align-items: center;
    margin-left: 15px;
    span{
      margin-right: 6px;
    }
    input{
      width: 32px;
      height: 32px;
      background: #FFFFFF;
      border-radius: 4px;
      border: 1px solid #E6E6E6;
      margin-right: 8px;
      text-align: center;
      font-size: 14px;
    }
    .btn{
      width: 32px;
      height: 32px;
      text-align: center;
      padding: 0;
      font-size: 14px;
    }
`

export default function Page({ itemsPerPage,total,handleToPage,handlePageSize }) {

    const [pageCount, setPageCount] = useState(0);
    const [pageSize,setPageSize] = useState();
    const [current, setCurrent] = useState(0);
    const [pageList]= useState(['10','50','100']);
    const [pageToGo,setPageToGo]= useState('');
    const [currentSize, setCurrentSize] = useState(0);
    const [show,setShow] = useState(false);


    useEffect(() => {
        document.addEventListener("click", (e) =>{
            setShow(false)
        });
    }, [])

    useEffect(() => {
        setPageCount(Math.ceil(total / itemsPerPage));
    }, [itemsPerPage,current,total]);

    useEffect(()=>{
        setPageSize(pageList[currentSize]);
        handlePageSize(pageList[currentSize]);
    },[currentSize]);
    useEffect(()=>{
        handleToPage(current)
    },[current]);

    const handlePageClick = (event) => {
        setCurrent(event.selected);

    }
    const handleSelected = (e) =>{
        setShow(true);
        e.nativeEvent.stopImmediatePropagation();
    }
    const handlePage = (num) =>{
        setCurrentSize(num);
        setShow(false);

    }
    const handleInput = (e) =>{
        const { value } = e.target;
        if(value >pageCount || value <1){
            setPageToGo('')
        }else{
            setPageToGo(e.target.value)
        }
    }
    const handleToGo = () =>{
        setCurrent(pageToGo-1);
    }

    return (
        <Box>
            <NumBox>
                Total <span>{total}</span> items
            </NumBox>
            <ReactPaginate
                previousLabel=""
                nextLabel=""
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-left"
                previousLinkClassName="pageL"
                nextClassName="page-right"
                nextLinkClassName="pageR"
                breakLabel="..."
                breakClassName="page-break"
                breakLinkClassName="page-break"
                pageCount={pageCount}
                marginPagesDisplayed={1}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName="pagination"
                activeClassName="active"
                forcePage={current}
            />
            <SelectedBox>
                {
                    show && <DropDownBox>
                        {
                            pageList.map((item,index)=>(
                                <li key={item} onClick={()=>handlePage(index)}>{item} / Page</li>
                            ))
                        }
                    </DropDownBox>
                }
                <div className="selected" onClick={handleSelected}>
                    <span>{pageList[currentSize]}/Page</span>
                    <img src={SelectedImg} alt=""/>
                </div>

            </SelectedBox>
            <GoToBox>
                <span>Goto</span>
                <input type="text" value={pageToGo} onChange={handleInput} />
                <Button className="btn" onClick={()=>handleToGo()}>Go</Button>
            </GoToBox>
        </Box>
    );
}