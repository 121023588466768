import {gql} from "@apollo/client";

export const LoginQuery = gql`
    query MyQuery($sign: String!, $account: String!, $timestamp: Int!,$chainID:Int!) {
        loginWithWhiteList(sign: $sign, input: { wallet: $account, timestamp: $timestamp,chain_id:$chainID }) {
            token
            tokenExp
        }
    }
`;
export const chainListQuery = gql`
    query MyQuery {
        chains {
            chain_id
            chain_name
        }
    }
`;
export const ventureDAOList = gql`
    query MyQuery($page: Int!, $size: Int!,$sort_field:String,$order:String,$period:Int,$chainID:Int,$search_txt:String) {
        ventureDAOList(page: {page: $page, size: $size,order:$order,sort_field:$sort_field},period:$period,chainID:$chainID,search_txt:$search_txt) {
            page
            size
            total
            data {
                success_vote_count
                nft_count
                buy_count
                loan_count
                
                
                
                chain_id
                create_at
                dao_address
                dao_id
                desc
                handle
                et_holders
                et_holders_growth_last_month
                et_holders_growth_last_week
                fund_token_address
                fund_token_symbol
                funding_participants
                fund_token_decimal
                gp_member_count
                logo
                name
                owner_addr
                period
                raised_funds
                raised_funds_usdt
                tav
                tav_growth_last_month
                tav_growth_last_week
                vault_addr
                ventures_addr
                vote_count
            }
        }
    }
`;
export const addressList = gql`
    query MyQuery($page: Int!, $size: Int!,$sort_field:String,$order:String,$search_addr:String,$chainID:Int) {
        fpAddressList(page: {page: $page, size: $size,order:$order,sort_field:$sort_field},chainID:$chainID,search_addr:$search_addr) {
            page
            size
            total
            data {
                total_raised_times_past_week
                total_redeem_times
                
                address
                ip
                chain_id
                ip_country
                last_raise_date
                total_raised_amount
                total_raised_amount_usdt
                total_raised_times
            }
        }
    }
`;

export const tavListQuery = gql`
    query MyQuery {
        tavList {
            chain_id
            date
            et_holders
            et_holders_growth_last_month
            et_holders_growth_last_week
            funding_participants
            gp_member_count
            id
            month
            raised_funds
            raised_funds_usdt
            tav
            tav_growth_last_month
            tav_growth_last_week
            vote_count
            year
            yearmonth
            yearmonthday
        }
    }
`;
export const tavGraphQuery = gql`
    query MyQuery($scope:String!,$chainID:Int) {
        tavGraph(chainID: $chainID, scope: $scope) {
            date
            value
        }
    }
`;


