import styled from "styled-components";
import {useState} from "react";
import ChainListJson from "../api/chainList.json"

const Box = styled.ul`
    position: absolute;
  background: #FFFFFF;
  border-radius: 8px;
  border: 1px solid #EDEEF3;
  left: 0;
  top:40px;
  width: 100%;
  padding: 10px 0;
  z-index: 999999;
  li{
    padding-left: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #2E396E;
    line-height: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 30px;
    margin-bottom: 1px;
    &:hover{
      background: #FBFBFA;
      cursor: pointer;
    }
    span{
      padding-left: 5px;
      font-size: 14px;
      font-weight: 400;
      color: #0E164E;
      line-height: 22px;
    }
    .font16{
      font-size: 16px;
    }
  }
  .symbol{
    width: 24px;
  }
`
export default function ChainList(props){
    const {handleSelectdChain,chainList} = props;

    return <Box>

        <li  onClick={()=>handleSelectdChain(null)}><span className="font16">All main-nets</span> </li>


        {
            chainList.map((item,index)=>( <li key={index}  onClick={()=>handleSelectdChain(item.chain_id)}>
                {
                    ChainListJson[item.chain_id] &&<img src={ChainListJson[item.chain_id].image} alt="" className="symbol" />
                }
                <span>{item.chain_name}</span>
            </li>))
        }

    </Box>
}