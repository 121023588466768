import styled from "styled-components";
import {Button} from "react-bootstrap";
import HomeImg from "./images/home.svg";
import Account from "./api/Account";
import {useWeb3} from "./api/contracts";
// import { LoginQuery } from "./api/graphqlAPI";
import {signMessage} from "./utils/sign";
// import {useLazyQuery} from "@apollo/client";
import {useState} from "react";

const Box = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`
const Mid = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  img{
    width: 430px;
  }
`
const Btn = styled(Button)`
  width: 240px;
  height: 51px;
  margin-top: 35px;
  font-size: 22px;
  font-family: "AvenirNext-Medium";
  font-weight: 500;
  color: #FFFFFF;
  border-radius: 14px;
`
const TipsBox = styled('div')`
    margin-top: 30px;
    font-size: 14px;
    text-transform: capitalize;
    color:#f00;
`

function Home() {
    const {  dispatch ,state} = useWeb3();
    const {error} = state;

    const connect = async() => {
        const accountData = await Account.account();
        if(accountData.type === "success"){
            dispatch({type: 'SET_ALLACCOUNT',payload:accountData.data});
            sessionStorage.setItem('account',accountData.data);
        } else {
            // dispatch({type: 'SET_ERROR',payload: {msg:"Please install MetaMask"}});
            console.log("Please install MetaMask")
        }
    }
    return (
        <Box>
            <Mid>
                <img src={HomeImg} alt=""/>
                <Btn variant="primary" onClick={()=>connect()}>Connect Wallet</Btn>
                <TipsBox>
                    {
                        error !=null &&   error
                    }
                </TipsBox>
            </Mid>
        </Box>
    );
}

export default Home;
