import styled from "styled-components";
import {tavListQuery} from "../../api/graphqlAPI";
import {useQuery} from "@apollo/client";
import {useEffect, useState} from "react";
import ChainListJson from "../../api/chainList.json";
import {ethers} from "ethers";
import BigNumber from "bignumber.js";

const Box = styled.div`
  width: calc(100vw - 255px);
  overflow-x: auto;
`

export default function TavList(){
    const { data:tavListData} = useQuery(tavListQuery);
    const [list, setList] = useState([]);
    useEffect(()=>{
        if (tavListData){
            setList(tavListData.tavList)
        }
    },[tavListData]);

    const sumNum = (type) =>{
        let sum=0;
        list.map(item=>{
            sum+=item[type];
        })
        return Number(sum);
    }
    return <Box>
        <table cellPadding="0" cellSpacing="0" className="table">
            <thead>
            <tr>
                <th>Main-net</th>
                <th>Total Assets Value(U)</th>
                <th>TAV growth in the past week</th>
                <th>TAV growth in the past month</th>
                <th>Raised Funds</th>
                <th>Funding Participants</th>
                <th>ET Holders</th>
                <th>ET-holder growth in the past week</th>
                <th>ET-holder growth in the past month</th>
                <th>GP Members</th>
            </tr>
            </thead>
            <tbody>
            {
                list.map((item,index)=>(<tr key={index}>
                    <td>
                        {
                            ChainListJson[item.chain_id] &&<><img src={ChainListJson[item.chain_id].image} alt="" className="symbol"/>
                                {ChainListJson[item.chain_id].name}</>
                        }
                    </td>
                    <td>{Number(item.tav).toFixed(2)}</td>
                    <td className="num">{Number(item.tav_growth_last_week).toFixed(2)}</td>
                    <td className="num">{Number(item.tav_growth_last_month).toFixed(2)}</td>
                    <td className="num">{Number(item.raised_funds_usdt).toFixed(2)}</td>
                    <td className="num">{item.funding_participants}</td>
                    <td className="num">{item.et_holders}</td>
                    <td className="num">{item.et_holders_growth_last_week}</td>
                    <td className="num">{item.et_holders_growth_last_month}</td>
                    <td className="num">{item.gp_member_count}</td>
                </tr>))
            }
            <tr>
                <td>All Main-nets</td>
                <td>{sumNum('tav').toFixed(2)}</td>
                <td className="num">{sumNum('tav_growth_last_week').toFixed(2)}</td>
                <td className="num">{sumNum('tav_growth_last_month').toFixed(2)}</td>
                <td className="num">{sumNum('raised_funds_usdt').toFixed(2)}</td>
                <td className="num">{sumNum('funding_participants')}</td>
                <td className="num">{sumNum('et_holders')}</td>
                <td className="num">{sumNum('et_holders_growth_last_week')}</td>
                <td className="num">{sumNum('et_holders_growth_last_month')}</td>
                <td className="num">{sumNum('gp_member_count')}</td>
            </tr>
            </tbody>
        </table>
    </Box>
}