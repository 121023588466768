import styled from "styled-components";
import {useEffect, useState} from "react";
import * as echarts from 'echarts';
import DownArr from "../../images/selected.png";
import ChainList from "../chainList";
import SelectedImg from "../../images/selected.png";
import {useLazyQuery,useQuery} from "@apollo/client";
import {tavGraphQuery, chainListQuery} from "../../api/graphqlAPI";
import ChainListJson from "../../api/chainList.json"

const ChartBox = styled.div`
  background: #FFFFFF;
  box-shadow: 0 0 10px 0 rgba(16,22,75,0.0500);
  border: 1px solid #F0F0F0;
  border-radius: 24px;
  padding: 24px 27px;
  height: 100%;
`

const TitleBox = styled.div`
    display: flex;
    justify-content: space-between;
  .lft{
    font-size: 16px;
    display: flex;
    align-items: center;
    color: #0C1651;
    line-height: 16px;
    img{
      margin-left: 8px;
    }
    span{
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      white-space: nowrap;
    }
  }
  .rht{
    min-width: 130px;
    white-space: nowrap;
    height: 44px;
    background: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #EDEEF3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 11px 0 23px;
    position: relative;
    img{
      width: 24px;
      height: 24px;
    }
    span{
      font-size: 14px;
    }
  }
  .rhtSelected{
    background: #fff;
  
  }
`
const DropBox = styled.ul`
  position: absolute;
  left: 0;
  top:55px;
  z-index: 9999;
  width: 190px;
  padding: 10px 0;
  background: #FFFFFF;
  border-radius: 8px 8px 14px 14px;
  border: 1px solid #EDEEF3;
  li{
    padding:6px 23px ;
    cursor: pointer;
    font-size: 14px;
    &:hover{
      background: #FBFBFA;
    }
  }
`
const RhtBox = styled.div`
    display: flex;
    align-items: center;
`

export default function TavChart(){

    const [show,setShow] = useState(false);
    const [list] = useState([
    {
        name:"1 month",
        value:"1M"
    },
    {
        name:"3 months",
        value:"3M"
    },
    {
        name:"6 months",
        value:"6M"
    },
    {
        name:"1 year",
        value:"1Y"
    }
    ]);
    const [current,setCurrent] = useState(0);
    const [showChain,setShowChain] = useState(false);
    const [currentChain,setCurrentChain] = useState(null);
    const [chainList,setChainList] = useState([]);
    const { data:chainListQ} = useQuery(chainListQuery);
    const [xList,setXList]=useState([]);
    const [valueList,setValueList]=useState([]);

    useEffect(()=>{
        if (chainListQ){
            setChainList(chainListQ.chains)
            // setCurrentChain(chainListQ.chains[0].chain_id)
        }
    },[chainListQ]);

    useEffect(()=>{
        initInfo()
    },[current,currentChain]);

    const [init, {data}] = useLazyQuery(tavGraphQuery,{
        onCompleted: data => {
            let xArr=[],valueArr=[];
            data.tavGraph.map(item=>{
                xArr.push(item.date)
                valueArr.push(Number(item.value).toFixed(2))
            });
            setValueList(valueArr);
            setXList(xArr);
        },
        onError: error => {
            console.log(error)
        }
    });

    const initInfo = () =>{
        init({
            variables:{
                scope:list[current].value,
                chainID:currentChain
            }
        })
    }

    useEffect(()=>{
        initEchart()
    },[xList,valueList])


    useEffect(()=>{

        document.addEventListener("click",()=>{
            setShow(false)
            setShowChain(false);
        })
    },[]);
    const initEchart =() =>{
        const myChart = echarts.init(document.getElementById('main'));
        window.onresize = function() {
            myChart.resize();
        };

        myChart.setOption({
            color: ['#ff7ebb'],
            title: {
                show: false
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#6a7985'
                    }
                }
            },
            grid: {
                x: 0,
                y: 30,
                x2: 10,
                y2: 10,
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    boundaryGap: false,
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: "(65,97,128,0.45)",
                            width: 1,
                            type: "solid"
                        },
                    },
                    axisLabel: {
                        textStyle: {
                            color: "rgba(12, 22, 81, .45)",
                            margin: 15
                        }
                    },
                    data: xList
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    axisLabel: {
                        textStyle: {
                            color: "rgba(44, 53, 66, 0.4500)",
                            margin: 15
                        }
                    }
                }
            ],
            series: [
                {
                    name: 'Line 1',
                    type: 'line',
                    stack: 'Total',
                    smooth: true,
                    lineStyle: {
                        width: 2
                    },
                    showSymbol: false,
                    areaStyle: {
                        opacity: 0.8,
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: '#F7cfe2'
                            },
                            {
                                offset: 1,
                                color: '#fff'
                            }
                        ])
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: valueList
                }

            ]
        });



    }

    const handleSelected = (e) =>{
        e.nativeEvent.stopImmediatePropagation();
        setShow(true);
        setShowChain(false);
    }
    const choose = (num,e) =>{
        e.stopPropagation();
        setShow(false);
        setCurrent(num);

    }
    const handleChain = (e) =>{
        e.nativeEvent.stopImmediatePropagation();
        setShowChain(true);
        setShow(false);
    }

    const handleSelectdChain = (num) =>{
        setCurrentChain(num)
    }

    return <ChartBox>
        <TitleBox>
            <div className="lft"> Total Assets Value</div>
            <RhtBox>
                <div className="selectedBox rhtSelected">
                    {
                        showChain && <ChainList handleSelectdChain={handleSelectdChain} chainList={chainList} />
                    }
                    <div className="content" onClick={handleChain}>
                        <div>
                            {
                                currentChain!=null &&ChainListJson[currentChain]?.image && <img src={ChainListJson[currentChain].image} alt="" className="symbolImg"/>
                            }
                            {
                                currentChain!=null&&<span className="lftName">{ChainListJson[currentChain]?.name}</span>
                            }
                            {
                                currentChain==null&&<span className="lftName">All main-nets</span>
                            }

                        </div>

                        <img src={SelectedImg} alt=""/>
                    </div>
                </div>
                <div className="rht" onClick={(e)=>handleSelected(e)}>
                    <span>{list[current].name}</span>
                    <img src={DownArr} alt=""/>
                    {
                        show && <DropBox>
                            {
                                list.map((item,index)=>(
                                    <li key={index} onClick={(e)=>choose(index,e)}>
                                        {item.name}
                                    </li>
                                ))
                            }
                        </DropBox>
                    }
                </div>
            </RhtBox>

        </TitleBox>
        <div id="main" style={{ width: '100%', height: '400px' }}  />
    </ChartBox>
}