import React, {useContext, useReducer} from 'react';
import reducer from './reducer';
import INIT_STATE from './initState';
import { ethers } from 'ethers';

const initState = {...INIT_STATE};

const Web3Context = React.createContext();

const connect = async (state, dispatch) => {
    const {web3ProviderState} = state;
    const {ethereum} = window;

    if (web3ProviderState!=null || typeof ethereum == 'undefined') return;
    dispatch({type: 'CONNECT_INIT'});
    const web3Provider = new ethers.providers.Web3Provider(ethereum);
    if(web3Provider){
        dispatch({type: 'CONNECT', payload:  web3Provider });
    }

};

const Web3ContextProvider = (props) => {
    const [state, dispatch] = useReducer(reducer, initState);
    console.log("=====state=====",state);
    const { web3Provider } = state;
    if(web3Provider == null ) {
        connect(state, dispatch);
    }



    return <Web3Context.Provider value={{state,dispatch}}>
        {props.children}
    </Web3Context.Provider>;
};

const useWeb3 = () => ({...useContext(Web3Context)});
export {Web3ContextProvider, useWeb3};
