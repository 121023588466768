import React from 'react';
import Home from '../home';
import Main from "../main"
import {Route,Routes,Navigate} from "react-router-dom";


function RouterLink() {
    return (
        <Routes>
            <Route path="/" element={<Navigate to="/home" />}/>
            <Route path="/home" element={<Home />} />
            <Route path="/main/:id" element={<Main />} />
        </Routes>
    );
}

export default RouterLink;
