import React, {useEffect} from "react";
import GlobalStyle from "./utils/GlobalStyle";
import {HashRouter as Router, useLocation} from 'react-router-dom'
import RouterLink from "./router/router";
import Header from "./components/header";
import styled from "styled-components";
import {Web3ContextProvider} from "./api/contracts";
import HttpProvider from "./components/httpProvider";
const Box = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    .content{
      flex-grow: 1;
    }
`

function App() {
  return (<HttpProvider>
            <Web3ContextProvider>
                <Router>
                    <Box>
                        <Header />
                        <div className="content">
                            <RouterLink />
                        </div>
                    </Box>
                </Router>
                <GlobalStyle />
            </Web3ContextProvider>
        </HttpProvider>
  );
}

export default App;
