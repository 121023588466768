
const clearStorage = () => {
  localStorage.removeItem("SubDAO_user_token");
  localStorage.removeItem("SubDAO_user_token_expire_at");
};

export const checkTokenValid = () => {
  const token = localStorage.getItem("SubDAO_user_token");
  const tokenExpire = localStorage.getItem("SubDAO_user_token_expire_at");

  if (!token || !tokenExpire) {
    return;
  }

  try {
    const now = Date.now();
    if (now >= Number(tokenExpire)) {
      clearStorage();
      return;
    }
    return true;
  } catch (error) {
    // invalid storage
    clearStorage();
  }
};
