module.exports = {
    web3Provider: null,
    web3ProviderError: null,
    error:null,
    token:null,
    errorTips:null,
    Account: null,
    accountState: null,

};
